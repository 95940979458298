<template>
  <footer id="footer">
    <div class="second-background">
      <div class="container-fluid center-max-1320-px p-3">
        <div class="row">
          <div class="col-md-3 footer-element">
            <h4 class="footer-h4">WE VALUE YOUR PRIVACY</h4>
            <p class="footer-paragraph">
              Your information provided is solely used by {{ instanceInfo?.name }} VPN for the use
              of a virtual private network. We will not sell your information to any other party.
            </p>
          </div>
          <div class="col-md-3 footer-element">
            <h4 class="footer-h4">CUSTOMER SUPPORT</h4>
            <p class="footer-paragraph">
              Our technical support team is ready to answer questions. You can contact them at
              {{ instanceInfo?.supportEmail }}
            </p>
          </div>
          <div class="col-md-3 footer-element">
            <h4 class="footer-h4">YOUR SATISFACTION IS OUR PRIORITY</h4>
            <p class="footer-paragraph">
              If you are in any way unhappy with the quality of our service or product please
              contact us. We are here to help you.
            </p>
          </div>
          <div class="col-md-3 footer-element">
            <h4 class="footer-h4">WHAT ARE THE BENEFITS OF A VPN?</h4>
            <p class="footer-paragraph">
              You can access content from different countries, which would not necessarily be
              available in your country. Also with a VPN you can hide your online presence to secure
              your daily internet activity
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="second-background">
      <div class="container-fluid center-max-1320-px p-3">
        <div class="row justify-content-center">
          <div class="col-md-3 footer-link">
            <router-link to="/privacy">Privacy Policy</router-link>
          </div>
          <div class="col-md-3 footer-link">
            <router-link to="/terms">Terms and conditions</router-link>
          </div>
          <div class="col-md-3 footer-link">
            <router-link to="/contact">Contact</router-link>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="container-fluid center-max-1320-px p-3">
        <div class="row">
          <div class="col-md-12 contact-bottom">
            <span class="rtl">{{ companyName }}</span
            >, {{ instanceInfo?.companyAddress1 }},
            {{ instanceInfo?.companyAddress2 }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PortalFooter',
  inheritAttrs: false,
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    companyName: {
      type: String,
      required: false,
    },
  },
};
</script>
