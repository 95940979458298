<template>
  <link :href="instanceInfo?.fontsUrl" rel="stylesheet" />
  <PortalHeader v-if="isInstanceInfoFetched" :instance-info="instanceInfo" />
  <router-view
    v-if="isInstanceInfoFetched"
    :instance-info="instanceInfo"
    :subscription-plan="subscriptionPlan"
    :home-page-template="homePageTemplate"
    :billing-model="billingModel"
    :are-the-same-billing-model="areTheSameBillingModel"
    :currencies="availableCurrencies"
    :billing-models="availableBillingModels"
    :company-name="companyName"
    @update:currency="updateCurrency"
    @update:billingModel="updateBillingModel"
  />

  <PortalFooter
    v-if="isInstanceInfoFetched"
    :instance-info="instanceInfo"
    :company-name="companyName"
  />

  <div v-if="fetchError" class="fetch-error-info">
    <h1>Website is under maintenance at the moment</h1>
    <h2>Please refresh page in few minutes</h2>
  </div>
</template>

<script>
import PortalHeader from '@/app/components/PortalHeader';
import PortalFooter from '@/app/components/PortalFooter';
import { computed, ref } from 'vue';
import { fetchInstanceInfo } from '@/app/api/instance';
import { fetchHomePageTemplate } from '@/app/api/home-page';

export default {
  components: { PortalHeader, PortalFooter },
  inheritAttrs: false,
  setup() {
    const isInstanceInfoFetched = ref(false);
    const fetchError = ref(false);

    const instanceInfo = ref(null);
    const subscriptionPlan = ref(null);
    const homePageTemplate = ref(null);
    const billingModel = ref(null);
    const availableCurrencies = ref([]);
    const selectedCurrency = ref(null);
    const availableBillingModels = ref([]);
    const companyName = ref('');

    fetchInstanceInfo()
      .then(async data => {
        instanceInfo.value = data;
        isInstanceInfoFetched.value = true;
        companyName.value = instanceInfo.value.companyName.split('').reverse().join('');
        selectedCurrency.value = instanceInfo.value.defaultCurrency;

        billingModel.value = instanceInfo.value.subscriptionPlans[0].billingModel;

        updateSubscriptionPlan();
        getAvailableCurrencies();
        getBillingModels();
      })
      .catch(() => {
        fetchError.value = true;
      });

    fetchHomePageTemplate()
      .then(async data => {
        homePageTemplate.value = data;
      })
      .catch(() => {
        fetchError.value = true;
      });

    const updateCurrency = newValue => {
      selectedCurrency.value = newValue;
      updateSubscriptionPlan();
      getBillingModels();
    };

    const updateBillingModel = newValue => {
      billingModel.value = newValue;
      updateSubscriptionPlan();
      getAvailableCurrencies();
    };

    const areTheSameBillingModel = computed(() => {
      if (!instanceInfo.value) {
        return false;
      }

      return instanceInfo.value.subscriptionPlans.every(
        item => item.billingModel === billingModel.value
      );
    });

    const updateSubscriptionPlan = () => {
      subscriptionPlan.value = instanceInfo.value.subscriptionPlans.find(
        plan => plan.currency === selectedCurrency.value && plan.billingModel === billingModel.value
      );
    };

    const getAvailableCurrencies = () => {
      availableCurrencies.value = instanceInfo.value.subscriptionPlans.reduce(
        (acc, currentValue) => {
          if (
            acc.indexOf(currentValue.currency) === -1 &&
            currentValue.billingModel === billingModel.value
          ) {
            acc.push(currentValue.currency);
          }
          return acc;
        },
        []
      );
    };

    const getBillingModels = () => {
      availableBillingModels.value = instanceInfo.value.subscriptionPlans.reduce(
        (acc, currentValue) => {
          if (
            acc.indexOf(currentValue.billingModel) === -1 &&
            currentValue.currency === selectedCurrency.value
          ) {
            acc.push(currentValue.billingModel);
          }
          return acc;
        },
        []
      );
    };

    return {
      isInstanceInfoFetched,
      fetchError,
      instanceInfo,
      subscriptionPlan,
      updateCurrency,
      homePageTemplate,
      billingModel,
      updateBillingModel,
      areTheSameBillingModel,
      availableCurrencies,
      availableBillingModels,
      companyName,
    };
  },
};
</script>
